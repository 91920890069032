import { lazy } from 'react';
import ApplicationSuccess from '../pages/ApplicationSignUp/ApplicationSuccess';
import CancelPayment from '../pages/CancelPayment';
import DeclinePayment from '../pages/DeclinePayment';
import RedirectPage from '../pages/RedirectPage';
const PaymentSuccess = lazy(() => import('../pages/PaymentSuccess'));
const DlPaymentSuccess = lazy(()=> import('../pages/DlPaymentSuccess'))
const CourseRegistration = lazy(() => import('../pages/CourseRegistration'));
const ApplicationRegistration = lazy(() => import('../pages/ApplicationSignUp'));
const Home = lazy(() => import('../pages/Home'));
const SignUp = lazy(() => import('../pages/SignUp'));
const SignIn = lazy(() => import('../pages/SignIn'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));
const Course = lazy(() => import('../pages/CourseDetail'));
const GhostLogin = lazy(() => import('../pages/GhostLogin'));
const NotFound = lazy(() => import('../pages/NotFound'));
const Payment = lazy(() => import('../pages/Payment'));
const TermsOfUse = lazy(() => import('../pages/TermsofUse'))
const Faqs = lazy( ()=> import('../pages/FAQs'));
const PaymentConfirmation = lazy(() => import('../pages/PaymentConfirmation'));


export const routes = [
    { path: '/apply', Component: ApplicationRegistration, title: "Application Registration" },
    { path: '/apply/:id', Component: ApplicationRegistration, title: "Application Registration" },
    { path: '/home', Component: Home, title: "Home" },
    { path: '/dl-application-status', Component: DlPaymentSuccess, title: "Application Status" },
    { path: '/404', Component: NotFound, title: "404" },
];
export const commonRoutes = [
    { path: '/course/:cid', Component: Course, title: "Course" },
    { path: '/program/:cid', Component: Course, title: "Program" },
    { path: '/program/:cid/:isLogin', Component: Course, title: "Program" },
    { path: '/program', Component: Course, title: "Program" },
    { path: '/auth_token/:userId/:token', Component: GhostLogin, title: "Authorization" },
    { path: '/payment', Component: Payment, title: "Payment" },
    { path: '/application-submited', Component: ApplicationSuccess, title: "Application Success" },
    { path: '/payment-success/:code/:id', Component: PaymentSuccess, title: "Payment Success" },
    { path: '/payment-success', Component: PaymentSuccess, title: "Payment Success" },
    { path: '/dl-payment-success', Component: DlPaymentSuccess, title: "Payment Success" },
    { path: '/dl-application-rejected', Component: DlPaymentSuccess, title: "DL Ppplication Rejected" },
    { path: '/dl-application-waitlisted', Component: DlPaymentSuccess, title: "DL Ppplication Waitlisted" },
    { path: '/cancel-payment', Component: CancelPayment, link: "https://distinguishedleaders.online.usc.edu/", title: "Cancel Payment" },
    { path: '/decline-payment', Component: DeclinePayment, link: "https://distinguishedleaders.online.usc.edu/", title: "Decline Payment" },
    // { path: '/apply', Component: RedirectPage, link: "https://precollege.usc.edu/online-programs/", title: "Application Registration" },
    { path: '/apply/:id', Component: ApplicationRegistration, title: "Application Registration" },
    { path: '/deposit-payment/:id', Component: CourseRegistration, title: "Deposit Registration" },
    { path: '/tuition-payment/:id', Component: CourseRegistration, title: "Tuition Registration" },
    { path: '/tuition-payment/:id/:half', Component: CourseRegistration, title: "Tuition Registration" },
    { path: '/dl-application-status/:id', Component: DlPaymentSuccess, title: "Application Status" },
    { path: '/apply/:id/:id', Component: ApplicationRegistration, title: "Application Registration" },
    { path: '/terms-of-use', Component: TermsOfUse, title: "Terms of Use" },
    { path:'/faqs', Component: Faqs, title: "FAQs" },
    // { path: '/freeapply', Component: ApplicationRegistration, title: "Application Registration" },
    { path: '/freeapply/:id', Component: ApplicationRegistration, title: "Application Registration" },
    { path: '/signin/:token', Component: SignIn, title: "Sign In" },
    { path: '/payment-confirmation/:id', Component: PaymentConfirmation, title: "Payment Confirmation" },
    { path: '/payment-confirmation/:id/:payment_plan', Component: PaymentConfirmation, title: "Payment Confirmation" },
];

export const publicRoutes = [
   
    { path: '/course-payment/:id', Component: CourseRegistration, title: "Course Registration" },
    { path: '/signup', Component: SignUp, title: "Sign Up" },
    // { path: '/apply/:id', Component: ApplicationRegistration, title: "Application Registration" },
    { path: '/signin', Component: SignIn, title: "Sign In" },
    { path: '/reset-password/:token', Component: ResetPassword, title: "Reset Password" },
];


