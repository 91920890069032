import Cookies from 'universal-cookie';
import axiosClient from "../../api/axiosClient";
import history from "../../history";
import { isAuthenticated } from '../../utils';

const cookies = new Cookies();


const CourseModel = {
    state: [],
    reducers: {
        setCourse(state, payload) {
            return [...payload];
        },
    },
    effects: (dispatch) => ({
        async getCourse() {
            let response = await axiosClient.get('/cert_user_course');
            this.setCourse(response.data.data)
        },
        async userApplication(data) {
            const { temp: payload, setPayload, setStepNumber, setSubmitLoader } = data;
            const { transaction_type, amount, payment_type,savelatter , handleLogout, secondbtn , ...rest } = payload;

            await axiosClient.post('/add_dl_application',rest).then(res => {
                if (res.data.status === 200) {
                    if( savelatter === undefined ){
                        if(secondbtn){
                            setPayload({...rest, transaction_type, amount, payment_type, application_id: res?.data?.data?.application_id });
                            console.log("rest",rest)
                            if(rest?.free_applied){
                                history.push(`/dl-application-status`);
                            }
                            setStepNumber(3);
                        }else{
                            setPayload({...rest, transaction_type, amount, payment_type, application_id: res?.data?.data?.application_id });
                        }
                    }
                    else if(!savelatter){
                    setPayload({...rest, transaction_type, amount, payment_type, application_id: res?.data?.data?.application_id });
                    if(rest?.free_applied){
                        history.push(`/dl-application-status`);
                    }
                    setStepNumber(3);
                    }else{
                        var d = new Date(Date.now() + 12 * (60 * 60 * 1000));
                        cookies.remove(`${process.env.REACT_APP_PREFIX}x-access-token`, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN, secure: true, sameSite: 'strict' });
                        cookies.remove(`${process.env.REACT_APP_PREFIX}x-access-token`, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN_LMS, secure: true, sameSite: 'strict' });
                        cookies.remove(`${process.env.REACT_APP_PREFIX}u_id`, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN, secure: true, sameSite: 'strict' });
                        cookies.remove(`${process.env.REACT_APP_PREFIX}rise_course_id`, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN_LMS, secure: true, sameSite: 'strict' });
                        cookies.remove(`${process.env.REACT_APP_PREFIX}rise_topic_id`, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN_LMS, secure: true, sameSite: 'strict' });
                        cookies.remove(`${process.env.REACT_APP_PREFIX}outline_id`, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN_LMS, secure: true, sameSite: 'strict' });
                        cookies.remove(`${process.env.REACT_APP_PREFIX}outline_ids`, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN_LMS, secure: true, sameSite: 'strict' });
                        cookies.remove(`${process.env.REACT_APP_PREFIX}user_name`, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN_LMS, secure: true, sameSite: 'strict' });
                        cookies.remove(`${process.env.REACT_APP_PREFIX}certificate_clg`, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN_LMS, secure: true, sameSite: 'strict' });
                        cookies.remove(`${'free_applied'}`, { path: '/', expires: d,  secure: true, sameSite: 'strict' });
                        localStorage.clear();
                        history.push('/signin');
                    }
                    setSubmitLoader(false)
                } else {
                    dispatch.errorModel.handleErrorPop({ header: "Error", body: res.data.message });
                }
            }).catch(err => {
                dispatch?.errorModel?.handleErrorPop({ header: "Error", body: err.message });
            });
        },
        async userApplicationCancel(payload) {
            await axiosClient.put('/dl_update_payment_status', payload).then(res => {
                if (res.data.status === 200) {
                    
                }
            }).catch(err => {
                dispatch.errorModel.handleErrorPop({ header: "Error", body: err.message });
            }).finally(() => {
                var d = new Date(Date.now() + 12 * (60 * 60 * 1000));
                cookies.remove(`${process.env.REACT_APP_PREFIX}x-access-token`, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN, secure: true, sameSite: 'strict' });
                cookies.remove(`${process.env.REACT_APP_PREFIX}x-access-token`, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN_LMS, secure: true, sameSite: 'strict' });
                cookies.remove(`${process.env.REACT_APP_PREFIX}u_id`, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN, secure: true, sameSite: 'strict' });
                cookies.remove(`${process.env.REACT_APP_PREFIX}rise_course_id`, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN_LMS, secure: true, sameSite: 'strict' });
                cookies.remove(`${process.env.REACT_APP_PREFIX}rise_topic_id`, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN_LMS, secure: true, sameSite: 'strict' });
                cookies.remove(`${process.env.REACT_APP_PREFIX}outline_id`, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN_LMS, secure: true, sameSite: 'strict' });
                cookies.remove(`${process.env.REACT_APP_PREFIX}outline_ids`, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN_LMS, secure: true, sameSite: 'strict' });
                cookies.remove(`${process.env.REACT_APP_PREFIX}user_name`, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN_LMS, secure: true, sameSite: 'strict' });
                cookies.remove(`${process.env.REACT_APP_PREFIX}certificate_clg`, { path: '/', expires: d, domain: process.env.REACT_APP_DOMAIN_LMS, secure: true, sameSite: 'strict' });
                localStorage.clear();
                // window.location.href = 'https://dev.distinguishedleaders.online.usc.edu/signin';
                window.location.href = `${process.env.REACT_APP_FRONT_URL_Dl}signin`
            });
        },
        async userApplicationPayment(payload) {
            let retry = localStorage.getItem('PaymentRetry');
            await axiosClient.post('/addDlAppPayment', payload).then(res => {
                if (res.data.status === 200) {
                    localStorage.removeItem('PaymentRetry');
                    history.push(`/dl-application-status`);
                } else {
                    if (+retry === 1) {
                        localStorage.removeItem('PaymentRetry');
                        dispatch.errorModel.handleErrorPop({ header: "Error", body: res.data.message });
                    } else {
                        localStorage.setItem('PaymentRetry', 1);
                        setTimeout(() => {
                            dispatch.CourseModel.userApplicationPayment(payload);
                        }, 1000);
                    } 
                   
                }
            }).catch(err => {
                dispatch.errorModel.handleErrorPop({ header: "Error", body: err.message });
            });
        },
        async userFinalPayment(payload) {
            let retry = localStorage.getItem('PaymentRetry');
            await axiosClient.post('/add_dl_deposit_payment', payload).then(res => {
                if (res.data.status === 200) {
                    if(isAuthenticated()){
                        history.push("/dl-application-status");
                    } else {
                        history.push(`/signin`);
                    }
                    window.location.reload()
                    localStorage.removeItem('PaymentRetry');
                    // if (res.data.redirect_page === "registration") {
                    //     history.push(`/payment-success/${res.data.code}/${res.data.course_id}`);
                    // } else {
                    //     history.push(`/signin`);
                    // }
                } else {
                    if (+retry === 1) {
                        localStorage.removeItem('PaymentRetry');
                        dispatch.errorModel.handleErrorPop({ header: "Error", body: res.data.message });
                    } else {
                        localStorage.setItem('PaymentRetry', 1);
                        setTimeout(() => {
                            dispatch.CourseModel.userFinalPayment(payload);
                        }, 1000);
                    }                    
                }
            }).catch(err => {
                dispatch.errorModel.handleErrorPop({ header: "Error", body: err.message });
            })
        },
        async userFinalTuitionPayment(payload) {
            let retry = localStorage.getItem('PaymentRetry');
            await axiosClient.post('/add_dl_tuition_payment', payload).then(res => {
                if (res.data.status === 200) {
                    localStorage.removeItem('PaymentRetry');
                    if(res.data.redirect_page === "half"){
                        if(isAuthenticated()){
                            history.push(`/dl-application-status`);
                            window.location.reload()
                        }else{
                            history.push(`/signin`);
                        }
                    }else if (res.data.redirect_page === "login") {
                        history.push(`/signin`);
                        // history.push(`/payment-success/${res.data.code}/${res.data.course_id}`);
                    }else {
                        history.push(`/signin`);
                    }
                } else {
                    if (+retry === 1) {
                        localStorage.removeItem('PaymentRetry');
                        dispatch.errorModel.handleErrorPop({ header: "Error", body: res.data.message });
                    } else {
                        localStorage.setItem('PaymentRetry', 1);
                        setTimeout(() => {
                            dispatch.CourseModel.userFinalTuitionPayment(payload);
                        }, 1000);
                    }                    
                }
            }).catch(err => {
                dispatch.errorModel.handleErrorPop({ header: "Error", body: err.message });
            })
        },
        async addbadgeEvent(payload) {
            await axiosClient.post('/add_cert_badge_event', payload).then(res => {
                if(res.status === 200){
                    console.log("successfully send couse name")
                }
            }).catch(err => {
                dispatch.errorModel.handleErrorPop({ header: "Error", body: err.message });
            })
        },
    }),
};

export default CourseModel;