import axiosClient from '../../api/axiosClient';
import {
  regardless,
  gender,
  howHeared,
  startDates,
  grade,
  residency,
  initialRadioOption,
  addressRadio,
  timeZone
} from '../../pages/ApplicationSignUp/json';
import Cookies from 'universal-cookie';

const ApplicationModel = {
  state: {
    courses: {
      allCourse: [],
      howHeared,
      startDates,
      gender,
      regardless,
      grade,
      residency,
      initialRadioOption,
      addressRadio,
      timeZone,
      courseDate: [],

    },
    studentDataById: {},
    program:'',
    applicatio_id:""
  },
  reducers: {
    // handle state changes with pure functions
    setAllCourse(state, payload) {
      return {
        ...state,
        [payload.name]: { ...state.courses, ['allCourse']: payload.data }
      };
    },
    setCustomOption(state, payload) {
      return {
        ...state,
        ['courses']: {
          ...state.courses,
          [payload.name]: [...state.courses[payload.name], payload?.value]
        }
      };
    },
    setCustomDates(state, payload) {
      return {
        ...state,
        ['courses']: { ...state.courses, [payload.name]: [...payload?.value] }
      };
    },
    setStudentDetails(state, payload) {
      return {
        ...state,
        ['studentDataById']: {
          ...state.studentDataById,
          studentDataById: payload.data
        }
      };
    },
     setApplicatio_id(state, payload) {
      return {
        ...state,
        ['applicatio_id']:payload.data
      };
    },
    setPrograid(state, payload) {
      return {
        ...state,
        ['program']:payload.data
      };
    },
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    async getAllCourse(payload) {
      await axiosClient
        .get(`/getCertAllCourse`)
        .then((res) => {
          if (res.data.status === 200) {
            this.setAllCourse({ name: 'courses', data: res.data.data });
          } else {
            dispatch.ErrorModel.handleErrorPop({
              header: 'Error',
              body: res.data.message
            });
          }
        })
        .catch((err) => {
          dispatch.ErrorModel.handleErrorPop({
            header: 'Error',
            body: err.message
          });
        });
    },
    async trackFormInAc(payload) {
      let params = new URLSearchParams({ ...payload })
      await axiosClient
        .get(`/dl_ac_contact?${params.toString()}`)
        .then((res) => {
          if (res.data.status === 200) {
            console.log("tracked...");
          } else {
            console.log("error in tracking...")
          }
        })
        .catch((err) => {
          console.log("error in tracking...")
        });
    },
    async getStudentDetails(id) {
      await axiosClient.get(`/get_dl_application_data_by_id/${id}`).then((res) => {
        if (res.data.status === 200) {
          this.setStudentDetails({ name: 'studentDataById', data: res.data.data });
        } else {
          dispatch.ErrorModel.handleErrorPop({
            header: 'Error',
            body: res.data.message
          });
        }
      }).catch((err) => {
        dispatch.ErrorModel.handleErrorPop({
          header: 'Error',
          body: err.message
        });
      })
    },
    async getPaymentToken(data) {
      const { temp: payload, setSubmitLoader, setRetryPop, usc_payment_id } = data;
      const { transaction_type, amount, payment_type, ...rest } = payload;
      
      await axiosClient
        .post('/get_dl_payment_token', {
          amount: amount,
          transaction_type: transaction_type
        })
        .then((res) => {
          if (res.data.status === 200) {
            if (res.data.token.includes("error")) {
              setSubmitLoader(false);
              setRetryPop(true);
              return;
            }
            const params = new URLSearchParams({
              ssl_txn_auth_token: `${res.data.token}`,
              ssl_description: `USC Distinguished Leaders Application Fee`,
              usc_payment_id,
              payment_type,
              // course_id: payload.course_id, 
              // product_name: payload.ssl_description,
              ssl_first_name: payload?.first_name,
              ssl_last_name: payload?.last_name,
              ssl_avs_address: payload?.address || "",
              ssl_address2: payload?.address2 || "",
              ssl_city: payload?.city || "",
              ssl_state: payload?.state || "",
              ssl_avs_zip: payload?.zip_code || "",
              ssl_country: payload?.country || "",
              ssl_phone: payload?.cell_phone || "",
              ssl_email: payload?.email || "",
              // domain_type: "PRE_COLLEGE",
              domain_type: "DL_PROGRAM",
            });
            window.open(
              `${process.env.REACT_APP_PAY_URL}?${params.toString()}`,
              '_self'
            );
          } else {
            setSubmitLoader(false);
            setRetryPop(true);
          }
        }).catch(err => {
          setSubmitLoader(false);
          setRetryPop(true);
        });
    },
    async getCoursePaymentToken(payload) {
      const { transaction_type, amount, payment_type, application_id, setLoader, setRetryPop } = payload;
      await axiosClient
        .post('/get_dl_payment_token', {
          amount: amount,
          transaction_type: transaction_type
        })
        .then((res) => {
          if (res.data.status === 200) {
            if (res.data.token.includes("error")) {
              setLoader(false);
              setRetryPop(true);
              return;
            }
            const params = new URLSearchParams({
              ssl_txn_auth_token: `${res.data.token}`,
              ssl_description: `${payload.ssl_description}`,
              payment_type,
              usc_payment_id: application_id,
              // course_id: payload.course_short_id, 
              // product_name: payload.ssl_description,
              ssl_first_name: payload?.first_name,
              ssl_last_name: payload?.last_name,
              ssl_avs_address: payload?.address || "",
              ssl_address2: payload?.address2 || "",
              ssl_city: payload?.city || "",
              ssl_state: payload?.state || "",
              ssl_avs_zip: payload?.zip_code || "",
              ssl_country: payload?.country || "",
              ssl_phone: payload?.cell_phone || "",
              ssl_email: payload?.email || "",
                // domain_type: "PRE_COLLEGE",
              domain_type: "DL_PROGRAM",
            });
            window.open(
              `${process.env.REACT_APP_PAY_URL}?${params.toString()}`,
              '_self'
            );
          } else {
            setLoader(false);
            setRetryPop(true);
          }
        }).catch(err => {
          setLoader(false);
          setRetryPop(true);
        });;
    },

    async setInstallmentPayment(payload) {
      await axiosClient
        .post('/update_dl_payment_plan', { ...payload })
        .then((res) => {
          if (res.data.status === 200) {
            if (res.data.token.includes("error")) {
              return;
            }
          } 
        }).catch(err => {
        
        });;
    },

    async payInstallmentPayment(payload) {
      await axiosClient
        .post('/add_dl_tuition_payment', { ...payload })
        .then((res) => {
          if (res.data.status === 200) {
            if (res.data.token.includes("error")) {
              return;
            }
          } 
        }).catch(err => {
        
        });;
    },

    async verifyUser(payload) {
      const { token, setFormData, history } = payload;
      const cookies = new Cookies();
      const programid  = cookies.get('programid')
      axiosClient.get(`/get_dl_email_from_token/${token}`).then(res => {
          if (res.data.status === 200) {
              setFormData((old) => { return { ...old, email: res.data.email } });
          } else if (res.data.status === 400) {
            history.push(`/apply/${programid}`);
          } else {
              console.log(`res`, res)
          }
      }).catch(err => {
          console.log(`err`, err)
      })
  },
  async getprogramid(payload) {
    axiosClient.get(`/get_dl_program/${payload}`).then(res => {
        if (res.data.status === 200) {
          this.setPrograid({ name: 'program', data: res.data.data });
        } else {
            console.log(`res`, res)
        } 
    }).catch(err => {
        console.log(`err`, err)
    })
},

    async getuserId(data) {
      await axiosClient.post('/add_certificate_user', data).then(res => {
        if (res.data.status === 200) {
          this.setApplicatio_id({ name: 'applicatio_id', data: res.data.data });
        }
      }).catch(err => {
          dispatch.errorModel.handleErrorPop({ header: "Error", body: err.message });
      });
  }, 
  async certEvent(data) {
    await axiosClient.post('/add_cert_event', data).then(res => {
      if (res.data.status === 200) {
        console.log("tracked...");
      } else {
        console.log("error in tracking...")
      }
    }).catch(err => {
        dispatch.errorModel.handleErrorPop({ header: "Error", body: err.message });
    });
},  
  })
};

export default ApplicationModel;
